import { pki } from "node-forge";
import { downloadFile } from "../libs/http/api"
export function getFilesPath(pathArr, key) {
    return new Promise(resolve => {
        let done = 0
        for (let i = 0; i < pathArr.length; i++) {
            const element = pathArr[i];
            if (element[key].indexOf('http') === 0) {
                element.mediaSource = element[key];
                if (++done == pathArr.length) {
                    resolve()
                }
            } else
                downloadFile(element[key]).then(({ url }) => {
                    element.mediaSource = url;
                    if (++done == pathArr.length) {
                        resolve()
                    }
                })
        }
    })
}

export async function downloadPic(arr, atrName) {
    let done = 0;
    return new Promise((resolve, reject) => {
        for (let i = 0; i < arr.length; i++) {
            downloadFile(arr[i][atrName]).then(({ url }) => {
                arr[i][atrName] = url;
                done++;
                if (done == arr.length) {
                    resolve();
                }
            });
        }
    });
}

/**
 * 获取防抖函数
 * @param {function} func 回调函数
 * @returns {function}
 */
export const antiShakeEvent = function () {
    // 缓存一个定时器
    let timer = 0
    // 这里返回的函数是每次用户实际调用的防抖函数 
    return function (func) {
        // 如果已经设定过定时器了就清空上一次的定时器
        if (timer) clearTimeout(timer)
        // 开始一个新的定时器，延迟执行用户传入的方法
        timer = setTimeout(() => {
            func()
        }, 500)
    }
}
//base64转file对象
export function base64toFile(base, filename) {
    const arr = base.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const suffix = mime.split("/")[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    //转换成file对象
    return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
    });
}
/**
 * file 转 base64
 * @param {object} file 
 * @returns promise of base64
 */
export function fileToBase64(file) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise(function (resolve) {
        reader.addEventListener("load", function () {
            return resolve(reader.result);
        });
    });
}
/**
 * 密码加密
 * @param {string} data 
 * @returns rsa data
 */
export function rsaEncrypt(data) {
    // 定义公钥
    const pemStr = `-----BEGIN RSA PUBLIC KEY-----
            MIIBCgKCAQEAsJfF5FE/B7RbssuKJae+MsWQ8BUJVYR9W4cz7VBBo6gBcV2jHviA
            B53r2gDQ+oTL7SDYmNtaGOX7Q6OoSKRm3eFHDM44HeGgdyRiLNNsnSeMAOGDJ9Yx
            PmLhbQwfYEjbntVcK18dLPsTFcOuPCvOeajXSvbsZVEidRPL+PNDJ+iiz5tDan0d
            v4wtD3zAvWD2nK97fuu6Guo3CCgIf9f2aPWEDxBlNOKb7IBeQDTp9ZKuya2lJtzT
            tBuREPcF2P/QYW7yo+54sLB9/Xn9W/bbiY07SOPOByRYSyyQV/B09VF0lTEn5ps6
            l/oG7yxiVBFqLx6XxkTr3/mtdE4aKCwchwIDAQAB
                    -----END RSA PUBLIC KEY-----`;
    let publicKey = pki.publicKeyFromPem(pemStr);
    // 获取到rsa加密结果
    let encrypted = publicKey.encrypt(data, 'RSAES-PKCS1-V1_5');
    // rsa加密后转base64
    return window.btoa(encrypted);
}

export async function replaceImageSrc(htmlString) {
    // 解析HTML字符串为DOM对象
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    // 获取所有的img标签
    const imgTags = doc.querySelectorAll('img');

    // 遍历每一个img标签
    for (let img of imgTags) {
        const path = img.getAttribute('data-href');
        if (path) {

            // 调用downloadFile函数获取图片URL
            const { url } = await downloadFile(path);
            // 替换src属性
            img.setAttribute('src', url);
        }
    }
    // 返回处理后的HTML字符串
    return doc.body.innerHTML;
}

// 进入全屏模式
export function enterFullscreen() {
    let element = document.documentElement; // 获取HTML元素
    let requestFullscreen =
        element.requestFullscreen ||
        element.mozRequestFullScreen ||
        element.webkitRequestFullscreen ||
        element.msRequestFullscreen;

    if (requestFullscreen) {
        requestFullscreen.call(element);
    }
}

// 退出全屏模式
export function exitFullscreen() {
    let exitFullscreen =
        document.exitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen ||
        document.msExitFullscreen;

    if (exitFullscreen && document.fullscreenElement) {
        exitFullscreen.call(document);
    }
}

// 绑定浏览器关闭事件
export function bindBrowserClose() {
    window.addEventListener('beforeunload', beforeUnload);
}

// 解绑浏览器关闭事件
export function unbindBrowserClose() {
    window.removeEventListener('beforeunload', beforeUnload);
}

function beforeUnload(event) {
    const message = 'Operation in progress, please do not close the browser';
    event.returnValue = message; // 标准的跨浏览器支持
    event.preventDefault();
    return; // 用于某些浏览器
}