<template>
    <a-modal v-model="visible" width="400rem" :footer="null" :closable="false" :destroyOnClose="true" centered
        :maskClosable="false" :zIndex="1100">
        <div class="text-center flex flex-col items-center">
            <p class="text-[24rem] text-gray-900 font-bold">Waiting for signature</p>
            <img class="w-[150rem] h-[150rem]" src="@/assets/loading.gif" alt="">
            <p class="text-[14rem] text-gray-500">Please sign the transaction in your wallet</p>
        </div>
    </a-modal>
</template>

<script>
import { bindBrowserClose, unbindBrowserClose } from '@/utils/tools';

export default {
    data() {
        return {
            visible: false
        }
    },
    watch: {
        visible(val) {
            if (val) {
                bindBrowserClose()
            } else {
                unbindBrowserClose()
            }
        }
    },
    mounted() {
        this.$bus.$on('waitingSignature', open => {
            this.changeVisible(open)
        })
    },
    destroyed() {
        this.$bus.$off('waitingSignature', this.changeVisible);
        unbindBrowserClose()
    },
    methods: {
        changeVisible(visible) {
            this.visible = visible
        }
    }
}
</script>