import Vue from 'vue'
import { children } from './children.js'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
let flag = false;
const routes = [
    {
        path: '/',
        redirect: '/system'
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/404.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login/index.vue'),
        beforeEnter: (_to, _from, next) => {
            flag = false;
            next();
        }
    },
    {
        path: '/system',
        name: 'system',
        component: () => import('@/components/System/index.vue'),
        children,
        beforeEnter: (to, from, next) => {
            // 0 = admin, 1 = compliance officer, 2 = marketplace operator, 4 = treasury 5 = first manager 6 = second manager
            if (!flag) {
                flag = true;
                const role = getUserRole();
                if (role) {
                    if (['0', '5', '6'].includes(role)) {
                        if (to.name === 'system')
                            next('/system/dashboard');
                        else
                            next();
                    } else if (role == 1) {
                        next('/system/KYCValidation');
                    } else if (role == 2) {
                        if (['Airdrop','CouponList', 'IssuedCoupon', 'Frame', 'Ambassador', 'GameOperator', 'GameOperatorInfo', 'Audit'].includes(to.name))
                            next();
                        else
                            next('/system/user')
                    } else if (role == 4) {
                        next("/system/WithdrawalSummary")
                    } else if (role == 7) {
                        next('/system/user')
                    }
                } else {
                    next("/login")
                }
            } else {
                next()
            }
        }
    },
    {
        path: '/dashboard-screen',
        name: 'dashboardScreen',
        component: () => import('@/views/Dashboard/Screen/index.vue')
    },
    {
        path: "*",
        redirect: "/404"
    }
]

const router = new VueRouter({
    routes
})
function getUserRole() {
    return window.localStorage.getItem("catalog") || "";
}
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function push(location) {
    return originalReplace.call(this, location).catch(err => err)
}
export default router
