<template>
  <div id="app" class="min-w-[980px] overflow-auto">
    <router-view />
    <WaitingSignature />
  </div>
</template>
<script>
import WaitingSignature from './components/WaitingSignature.vue';
export default {
  components: {
    WaitingSignature
  },
}
</script>
<style lang="less">
@import url("./styles/reset.css");
@import url("./styles/common.less");
</style>
